@import 'src/assets/css/vars.scss';

.wrapper {
  min-height: 100%;
  height: -webkit-fill-available;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 216px;
  margin-bottom: 24px;

  @media screen and (min-width: $screen-md-min) {
    width: 314px;
  }
}

.heading {
  font-family: $headingFont;
  font-style: normal;
  text-align: center;

  &.primary {
    font-size: 40px;
    line-height: 0.83;
    margin-bottom: 24px;
  }

  &.secondary {
    font-size: 36px;
    line-height: 1.1;
    margin-bottom: 24px;
  }

  &.small {
    font-size: 36px;
    line-height: 1.1;
    margin-bottom: 24px;
  }

  @media screen and (min-width: $screen-md-min) {
    &.primary {
      font-size: 50px;
      line-height: 1.2;
    }

    &.secondary {
      font-size: 60px;
      line-height: 1.2;
    }
  }
}

.dscrText {
  font-weight: 400;
  font-size: 24px;
  line-height: 1.17;
  text-align: center;
  white-space: pre-line;

  & a {
    text-decoration: underline;
    transition: color 0.12s ease-out;
  }

  @media (hover: hover) {
    & a:hover {
      color: $orangeColor;
    }
  }
}

.introBlock {
  width: 100%;
  max-width: 450px;
  text-align: center;
  margin: 0 auto;
}

.authBtnBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  & > * {
    margin: 8px;
  }
}

.promoBlock {
  background-color: $whiteColor;
  border: 2px dashed $lightOrangeColor;
  margin-top: 16px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 1.25;

  & em {
    font-family: $boldFont;
    color: $orangeColor;
  }
}
