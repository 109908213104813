@import 'src/assets/css/vars.scss';

.wrapper {
  max-width: 674px;
  margin: 0 auto;
  word-break: break-word;

  & h2,
  & h3 {
    font-family: $headingFont;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  & h2 {
    font-size: 24px;
  }

  & h3 {
    font-size: 20px;
  }

  & p,
  & ul,
  & ol {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.33;
    padding-bottom: 1em;
  }

  & ol {
    counter-reset: list;
  }

  & ol li {
    position: relative;
    padding-bottom: 1em;
    padding-left: 24px;
  }

  & ol li:before {
    position: absolute;
    left: 0;
    top: 0;
    counter-increment: list;
    content: counter(list) ') ';
    color: $orangeColor;
    font-family: $boldFont;
  }

  & a {
    color: $orangeColor;
  }

  & strong {
    font-weight: 600;
  }

  & em {
    font-family: $boldFont;
    color: $orangeColor;
  }
}
