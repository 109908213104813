.heading {
  font-family: $headingFont;

  &--lg {
    font-size: 60px;
    line-height: 50px;
  }
}

.main-heading {
  font-family: $headingFont;
  font-size: 36px;
  line-height: 1.11;
  text-align: center;
  margin-bottom: 32px;
  white-space: pre-line;

  @media screen and (min-width: $screen-lg-min) {
    font-size: 60px;
    line-height: 0.83;
  }
}

$aligns: center, left, right;

@each $align in $aligns {
  .text-align-#{$align} {
    text-align: $align;
  }
}
