@import 'src/assets/css/vars.scss';

.errorNotice {
  width: 100%;
  padding: 16px;
  background-color: $dangerColor;
  color: $whiteColor;
  border-radius: 10px;
  margin-bottom: 16px;
}
