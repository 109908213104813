@import 'src/assets/css/vars.scss';

.wrapper {
}

.inner {
  position: relative;
  display: block;
  padding: 4px 0 4px 40px;
}

.input {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  border: 2px solid $strokeColor;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.12s linear;

  @media (hover: hover) {
    &:hover {
      border-color: $greyColor;
    }
  }

  &:before {
    content: '\e907';
    @include inline-icon;
    font-size: 18px;
    color: transparent;
    transition: color 0.12s linear;
  }

  input:checked + & {
    border-color: $greyColor;
  }

  input:checked + &:before {
    color: $greyColor;
  }
}

.label {
  display: inline-block;
  text-align: left;
  font-size: 12px;
  line-height: 1.16;
}

.error {
}
