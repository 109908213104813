@import 'src/assets/css/vars.scss';

.wrapper {
}

.label {
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 4px;
  text-align: left;
}

.input {
  min-width: 1%;
  width: 100%;
  min-height: 44px;
  background-color: $whiteColor;
  color: $greyColor;
  border: 1px solid $borderColor;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  padding: 0 14px;
  margin: 0;
  text-align: left;
  appearance: none;
  -moz-appearance: none;
  transition: border-color 0.16s linear, color 0.16s linear;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (hover: hover) {
    &:hover {
      border-color: $greyColor;
    }
  }

  &:focus {
    border-color: $orangeColor;
    outline: none;
  }

  .hasError & {
    border-color: $dangerColor;
    color: $dangerColor;
  }

  &:disabled {
    background-color: $strokeColor;
  }

  &:read-only {
    background-color: $strokeColor;
  }
}

.error {
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.33;
  margin-top: 4px;
  text-align: left;
  color: $dangerColor;
}

.comment {
  display: block;
  margin-top: 8px;
  text-align: right;
}

.tooltip {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;

  & i {
    font-size: 20px;
    color: $strokeColor;
  }
}

.tooltipContainer {
  position: absolute;
  bottom: 30px;
  left: -80px;
  width: 260px;
  background-color: #f7e0ce;
  border-radius: 10px;
  padding: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.25;
  color: $greyColor;
  opacity: 0;
  visibility: hidden;
  transform: translateY(4px);
  transition: opacity 0.12s ease-out, visibility 0s ease-out 0.12s,
    transform 0.12s ease-out;

  .tooltip:hover & {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: opacity 0.12s ease-out, visibility 0s ease-out 0s,
      transform 0.12s ease-out;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 78px;
    width: 0px;
    height: 0px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #f7e0ce;
  }

  & ul {
    list-style: disc;
    list-style-position: inside;
  }
}
