@import 'src/assets/css/vars.scss';

.wrapper {
  padding-bottom: 80px;

  &.isPreview {
    padding-top: 64px;
  }
}

.header {
  position: relative;
  margin-bottom: 16px;

  @media screen and (min-width: $screen-md-min) {
    display: flex;
    justify-content: center;
    padding: 0 140px;
    min-height: 46px;
  }
}

.headerBackContainer {
  margin-bottom: 18px;

  @media screen and (min-width: $screen-md-min) {
    position: absolute;
    top: 16px;
    left: 0;
  }
}

.headerMain {
  text-align: center;
}

.headerIcon {
  color: $orangeColor;
  font-size: 24px;
}

.headerHeading {
  font-family: $headingFont;
  font-size: 24px;
  line-height: 1;
}

.headerSubtitle {
  font-size: 18px;
  line-height: 1.33;

  .headerHeading + & {
    margin-top: 8px;
  }
}

.headerLogo {
  position: absolute;
  top: 0;
  right: 0;
  width: 130px;

  @media screen and (max-width: $screen-sm-max) {
    display: none;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 80;
  padding: 16px 20px;
  background-color: tranparent;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: block;
    height: 162px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      $whiteColor 70.31%
    );
    pointer-events: none;
  }
}

.footerInner {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  & > * {
    margin: 4px;
  }

  @media screen and (min-width: $screen-md-min) {
    & > * {
      margin: 8px;
    }
  }
}
