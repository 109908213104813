@import 'src/assets/css/vars.scss';

.wrapper {
  position: relative;
}

.toggle {
  display: inline-flex;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.22;
  padding: 0;
  transition: color 0.12s ease-out;

  &:after {
    content: '\e90c';
    @include inline-icon;
    margin-left: 6px;
  }

  .wrapper.isOpen &:after {
    content: '\e90d';
  }

  @media (hover: hover) {
    &:hover {
      color: $orangeColor;
    }
  }
}

.menu {
  position: absolute;
  align-items: center;
  top: 100%;
  left: 0;
  z-index: 90;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  padding: 4px 0;
  width: 160px;
  transform: translateY(-4px);
  transition: visibility 0s ease-out 0.13s, opacity 0.13s ease-out,
    transform 0.13s ease-out;

  .wrapper.isOpen & {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: visibility 0s ease-out 0s, opacity 0.13s ease-out,
      transform 0.13s ease-out;
  }
}

.list {
  background-color: $whiteColor;
  box-shadow: 0px 0px 20px rgba(213, 213, 213, 0.4);
  border-radius: 5px;
  padding: 6px 0;
}

.listItem {
}

.listLink {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.22;
  padding: 6px 12px;
  transition: color 0.12s ease-out;

  &.isSelected {
    background-color: $mutedColor;
    pointer-events: none;
  }

  &.isSelected:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: $orangeColor;
  }

  @media (hover: hover) {
    &:hover {
      color: $orangeColor;
    }
  }
}
