@import 'src/assets/css/vars.scss';

.wrapper {
  min-height: 100%;
  height: -webkit-fill-available;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;
}

.inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 164px;
}
