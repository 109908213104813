html,
body,
#root {
  min-height: 100%;
  height: -webkit-fill-available;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior-y: contain;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $bgColor;
  color: $greyColor;
  font-family: $primaryFont;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1036px;
  margin: 0 auto;
  padding: 0 20px;
}

$margins: 4, 6, 8, 16, 24, 32;

@each $margin in $margins {
  .mt-#{$margin} {
    margin-top: $margin + px;
  }

  .mr-#{$margin} {
    margin-right: $margin + px;
  }

  .mb-#{$margin} {
    margin-bottom: $margin + px;
  }

  .ml-#{$margin} {
    margin-left: $margin + px;
  }
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.row {
  display: flex;

  &.align-center {
    align-items: center;
  }

  &.align-right {
    align-items: flex-end;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}
