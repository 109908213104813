@import 'src/assets/css/vars.scss';

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.71;
  padding: 0;
  transition: background-color 0.12s ease-out, color 0.12s ease-out,
    border-color 0.12s ease-out;

  &.primary,
  &.secondary,
  &.primary-outline,
  &.secondary-outline {
    height: 50px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    min-width: 166px;
    border-radius: 10px;
  }

  &.primary {
    background-color: $orangeColor;
    color: $whiteColor;
  }

  &.secondary {
    background-color: $greyColor;
    color: $whiteColor;
  }

  &.primary-outline {
    border: 1px solid $orangeColor;
    background-color: transparent;
    color: $orangeColor;
  }

  &.secondary-outline {
    border: 1px solid $strokeColor;
    background-color: transparent;
    color: $greyColor;
  }

  &.link {
    font-weight: 400;
    text-decoration: underline;
  }

  &.lg {
    font-size: 18px;
    line-height: 1.33;
  }

  @media (hover: hover) {
    &.primary:hover {
      color: $greyColor;
    }

    &.secondary:hover {
      color: $orangeColor;
    }

    &.primary-outline:hover {
      color: $greyColor;
      border-color: $greyColor;
    }

    &.secondary-outline:hover {
      color: $orangeColor;
      border-color: $orangeColor;
    }

    &.link:hover {
      color: $orangeColor;
    }
  }

  &.fullwidth {
    width: 100%;
  }

  &.wide {
    padding: 0 50px;
  }

  &.isLoading {
    pointer-events: none;
  }

  &.primary.isLoading,
  &.secondary.isLoading {
    color: transparent;
    transition: color 0s linear;
  }

  &.isLoading:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -14px 0 0 -14px;
    width: 21px;
    height: 21px;
    border-style: solid;
    border-radius: 50%;
    border-width: 3px;
    animation: spin 1s linear infinite;
  }

  &.primary.isLoading:after,
  &.secondary.isLoading:after {
    border-color: rgba($whiteColor, 0.4);
    border-top-color: $whiteColor;
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btnIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 23px;
  padding: 0;
  transition: background-color 0.12s ease-out, color 0.12s ease-out;

  &.primary {
    background-color: $orangeColor;
    color: $whiteColor;
  }

  &.secondary {
    background-color: $greyColor;
    color: $orangeColor;
  }

  &.primary-outline {
    border: 1px solid $orangeColor;
    background-color: transparent;
    color: $orangeColor;
  }

  &.secondary-outline {
    border: 1px solid $greyColor;
    background-color: transparent;
    color: $greyColor;
  }

  &.hasShadow {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  @media (hover: hover) {
    &.primary:hover {
      color: $greyColor;
    }

    &.secondary:hover {
      color: $orangeColor;
    }

    &.primary-outline:hover {
      background-color: $orangeColor;
      color: $whiteColor;
    }

    &.secondary-outline:hover {
      background-color: $greyColor;
      color: $orangeColor;
    }
  }
}
