@import 'src/assets/css/vars.scss';

.wrapper {
  width: 100%;
  margin: 36px 0;
}

.slide {
}

.slideInner {
  text-align: center;
  padding: 14px 16px;
}

.slideImg {
  width: 166px;
  height: 166px;
  object-fit: contain;
  border-radius: 50%;
  margin: 0 auto;

  @media screen and (min-width: $screen-lg-min) {
    width: 250px;
    height: 250px;
  }
}

.slideTitle {
  font-family: $headingFont;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  margin-top: 24px;
}

.slideDscr {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.33;
  margin-top: 12px;
}

.dotsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0 24px 0;
}

.dot {
  display: inline-flex;
  width: 6px;
  height: 6px;
  padding: 0;
  border-radius: 50%;
  margin: 0 8px;
  background-color: $strokeColor;
  font-size: 0;

  :global(.slick-active) & {
    background-color: $orangeColor;
  }
}
