@import 'src/assets/css/vars.scss';

.toggle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  font-size: 0;

  &:before {
    content: '\e90a';
    @include inline-icon;
    font-size: 38px;
  }
}

.wrapper {
  position: absolute;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background-color: rgba(51, 51, 51, 0.9);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.12s ease-out, visibility 0s ease-out 0.12s;

  .wrapper.isOpen & {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.12s ease-out, visibility 0s ease-out 0s;
  }
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background-color: $whiteColor;
  width: 286px;
  max-width: 100vw;
  transform: translateX(100%);
  transition: transform 0.12s ease-out;

  .wrapper.isOpen & {
    transform: translateX(0);
  }
}

.closeBtn {
  position: absolute;
  top: 26px;
  right: 26px;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 2px solid $greyColor;
  font-size: 0;
  padding: 0;
  transition: color 0.12s ease-out, border-color 0.12s ease-out;

  &:before {
    content: '\e903';
    @include inline-icon;
    font-size: 28px;
  }

  @media (hover: hover) {
    &:hover {
      border-color: $orangeColor;
      color: $orangeColor;
    }
  }
}

.menuInner {
  max-height: 100%;
  overflow: auto;
  padding: 56px 24px;
}

.primaryList {
}

.primaryItem {
  & + & {
    margin-top: 32px;
  }
}

.primaryLink {
  font-weight: 400;
  font-size: 24px;
  line-height: 1.22;
  vertical-align: middle;
  transition: color 0.12s ease-out;

  &:global(.active) {
    color: $orangeColor;
  }

  & :global(.icon) {
    font-size: 20px;
  }

  @media (hover: hover) {
    &:hover {
      color: $orangeColor;
    }
  }
}

.secondaryList {
  margin-top: 64px;
}

.secondaryItem {
  & + & {
    margin-top: 16px;
  }
}

.secondaryLink {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 1.22;
  padding: 0;
  transition: color 0.12s ease-out;

  &:global(.active) {
    color: $orangeColor;
  }

  @media (hover: hover) {
    &:hover {
      color: $orangeColor;
    }
  }
}
