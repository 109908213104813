@import 'src/assets/css/vars.scss';

.wrapper {
}

.label {
}

.input {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 28px;
  border-radius: 38px;
  border: 1px solid $borderColor;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: $borderColor;
    transition: transform 0.13s ease-out;
  }

  input:checked + &:before {
    background-color: $orangeColor;
    transform: translateX(20px);
  }

  input:disabled + & {
    opacity: 0.6;
  }
}
