@import 'src/assets/css/vars.scss';

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 8px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba($greyColor, 0.6);
}

.inner {
  position: relative;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 8px 0;
  pointer-events: none;

  @media screen and (min-width: $screen-md-min) {
    margin: 60px 0;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $bgColor;
  border-radius: 10px;
  width: 100%;
  max-width: 640px;
  padding: 24px;
  border: 1px solid $borderColor;
  box-shadow: 0px 0px 20px rgba(213, 213, 213, 0.4);
  pointer-events: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.heading {
  font-family: $headingFont;
  font-size: 22px;
  line-height: 1;

  @media screen and (min-width: $screen-md-min) {
    font-size: 36px;
  }
}

.dscr {
  font-size: 16px;
  line-height: 1.33;

  & a {
    color: $orangeColor;
    transition: color 0.12s linear;
  }

  @media (hover: hover) {
    & a:hover {
      color: $greyColor;
    }
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 16px -8px;

  & > * {
    margin: 6px 8px;
  }

  @media screen and (max-width: $screen-sm-max) {
    & > * {
      width: 100%;
    }
  }
}

.accordion {
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
}

.accordionHeader {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 16px 32px 16px 0;

  &:after {
    content: '\e90c';
    @include inline-icon;
    position: absolute;
    top: 8px;
    right: 0;
    font-size: 32px;
  }

  .accordion.isOpen &:after {
    content: '\e90d';
  }
}

.accordionTitle {
  font-family: $headingFont;
  font-size: 18px;
  line-height: 1;

  @media screen and (min-width: $screen-md-min) {
    font-size: 22px;
  }
}

.accordionBody {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: opacity 0.13s linear;

  .accordion.isOpen & {
    max-height: 100%;
    opacity: 1;
  }
}

.accordionContent {
  padding: 16px 0 24px 0;
}

.accordionRow {
  & + & {
    margin-top: 24px;
  }

  @media screen and (max-width: $screen-sm-max) {
    & button {
      width: 100%;
    }
  }
}

.checkboxItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkboxLabel {
  font-size: 18px;
}

.checkboxDscr {
  font-size: 14px;
  line-height: 1.33;
  max-width: 80%;
  margin-top: 12px;
}
