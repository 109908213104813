@import 'src/assets/css/vars.scss';

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba($greyColor, 0.6);
  cursor: pointer;
  transition: opacity 0.13s linear;

  :global(.modal-enter) & {
    opacity: 0;
  }

  :global(.modal-enter-active) & {
    opacity: 1;
  }

  :global(.modal-exit) & {
    opacity: 1;
  }

  :global(.modal-exit-active) & {
    opacity: 0;
  }
}

.inner {
  position: relative;
  z-index: 99;
  display: flex;
  justify-content: center;
  width: 100%;
  pointer-events: none;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $whiteColor;
  border-radius: 10px 10px 0 0;
  width: 100%;
  box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
  margin-top: 64px;
  transition: opacity 0.2s linear, transform 0.2s linear;

  :global(.modal-enter) & {
    opacity: 0;
    transform: translateY(100px);
  }

  :global(.modal-enter-active) & {
    opacity: 1;
    transform: translateY(0);
  }

  :global(.modal-exit) & {
    opacity: 1;
    transform: translateY(0);
  }

  :global(.modal-exit-active) & {
    opacity: 0;
    transform: translateY(100px);
  }
}

.contentInner {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 28px 0;
  height: 100%;

  @media screen and (min-width: $screen-md-min) {
    padding: 58px 0 32px 0;
  }
}

.closeBtn {
  position: absolute;
  top: -23px;
  right: 0;
  z-index: 99;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  padding: 0;
  border-radius: 50%;
  background: $greyColor;
  color: $orangeColor;
  font-size: 0;
  transition: color 0.13s ease-out;

  &:before {
    content: '\e903';
    @include inline-icon;
    font-size: 20px;
  }

  @media (hover: hover) {
    &:hover {
      color: $whiteColor;
    }
  }
}

.title {
  position: absolute;
  top: -12px;
  left: 50%;
  z-index: 99;
  transform: translateX(-50%);
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  border-radius: 24px;
  background-color: $whiteColor;
  border: 1px solid $strokeColor;
  font-family: $headingFont;
  font-size: 12px;
  line-height: 11px;
  text-align: center;
  text-transform: uppercase;
}

.footer {
  position: sticky;
  bottom: 0;
  z-index: 99;
  padding: 24px 20px;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: block;
    height: 162px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      $whiteColor 70.31%
    );
    pointer-events: none;
  }
}

.footerInner {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: $screen-md-min) {
    max-width: 328px;
  }
}

.header {
  margin-bottom: 32px;

  @media screen and (min-width: $screen-md-min) {
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: $whiteColor;
    padding-top: 24px;
    margin-top: -24px;
  }
}

.headerPrimary {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: $screen-md-min) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.horse {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-family: $headingFont;
  font-style: normal;
  font-size: 24px;
  line-height: 24px;

  & i {
    font-size: 24px;
    color: $orangeColor;
  }

  @media screen and (min-width: $screen-md-min) {
    flex-direction: row;

    & i {
      margin-right: 16px;
    }
  }
}

.headerDate {
  width: 100%;
  margin-top: 24px;

  @media screen and (min-width: $screen-md-min) {
    width: 328px;
    margin: 0;
  }
}

.headerSecondary {
  position: relative;
  text-align: center;
  margin-top: 24px;

  @media screen and (min-width: $screen-md-min) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }
}

.headerSecondaryLine {
  display: inline-block;
  height: 1px;
  background-color: $borderColor;
  width: 100%;

  @media screen and (min-width: $screen-md-min) {
    width: calc(100% - 50px);

    .headerSecondary.withCopy & {
      width: calc(100% - ((100% - 240px) / 2));
    }
  }
}

.headerCopyBtn {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.71;
  color: $orangeColor;
  margin-left: auto;
  margin-top: 16px;
  white-space: nowrap;
  transition: color 0.12s linear;

  &:before {
    content: '\e909';
    @include inline-icon;
    margin-right: 4px;
    font-size: 16px;
  }

  @media (hover: hover) {
    &:hover {
      color: $greyColor;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    margin-top: 0;
  }
}

.scrollProgressContainer {
  position: absolute;
  bottom: 0;
  height: 2px;
  width: calc(100% - 50px);

  @media screen and (max-width: $screen-sm-max) {
    display: none;
  }

  @media screen and (min-width: $screen-md-min) {
    &.withCopy {
      bottom: 13px;
      width: calc(100% - ((100% - 240px) / 2));
    }
  }
}

.scrollProgress {
  height: 3px;
  border-radius: 3px;
  background-color: $orangeColor;
}

.scrollLabel {
  position: absolute;
  top: -6px;
  right: -48px;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

.listDownBtn {
  position: absolute;
  bottom: 64px;
  right: -42px;
  margin-top: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: transparent;
  color: $greyColor;
  font-size: 0;
  pointer-events: all;

  &:before {
    content: '\e910';
    @include inline-icon;
    font-size: 22px;
  }

  @media screen and (max-width: $screen-sm-max) {
    display: none;
  }
}
