@import 'src/assets/css/vars.scss';

.item {
  & + & {
    margin-top: 8px;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 24px;

  & > * {
    margin: 6px 8px;
  }
}
