@import 'src/assets/css/vars.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.mediaCol {
  max-width: 400px;

  @media screen and (max-width: $screen-md-max) {
    display: none;
  }
}

.img {
  width: 400px;
  height: 400px;
  border-radius: 50%;
}

.mainCol {
  flex: 1;
  padding: 16px 0;

  & p {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.33;
  }

  & p + p {
    margin-top: 14px;
  }

  & em {
    font-family: $boldFont;
    color: $orangeColor;
  }

  @media screen and (min-width: $screen-lg-min) {
    .mediaCol + & {
      margin-left: 64px;
    }
  }
}

.mediaContent {
  margin-top: 16px;
}
