@import 'src/assets/css/vars.scss';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: $bgColor;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  font-size: 0;
  border-style: solid;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: auto;
  width: 60px;
  height: 60px;
  border-width: 6px;
  border-color: rgba($orangeColor, 0.2);
  border-top-color: $orangeColor;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
