@import 'src/assets/css/vars.scss';

.wrapper {
  min-height: 100vh;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;
  padding-bottom: 64px;

  @media screen and (max-width: $screen-md-max) {
    background-size: 0 0;
  }
}

.inner {
}
