@import 'src/assets/css/vars.scss';

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 8px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba($greyColor, 0.6);
  cursor: pointer;
  transition: opacity 0.13s ease-out;

  :global(.modal-enter) & {
    opacity: 0;
  }

  :global(.modal-enter-active) & {
    opacity: 1;
  }

  :global(.modal-exit) & {
    opacity: 1;
  }

  :global(.modal-exit-active) & {
    opacity: 0;
  }
}

.inner {
  position: relative;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 60px 0;
  pointer-events: none;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $bgColor;
  border-radius: 10px;
  width: 100%;
  border: 1px solid $borderColor;
  box-shadow: 0px 0px 20px rgba(213, 213, 213, 0.4);
  pointer-events: auto;
  transition: opacity 0.13s ease-out, transform 0.13s ease-out;

  &.sm {
    max-width: 420px;
  }

  :global(.modal-enter) & {
    opacity: 0;
    transform: translateY(8px);
  }

  :global(.modal-enter-active) & {
    opacity: 1;
    transform: translateY(0);
  }

  :global(.modal-exit) & {
    opacity: 1;
    transform: translateY(0);
  }

  :global(.modal-exit-active) & {
    opacity: 0;
    transform: translateY(8px);
  }
}
