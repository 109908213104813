@import 'src/assets/css/vars.scss';

.wrapper {
  padding: 48px 20px;
}

.header {
  border-bottom: 1px solid $borderColor;
  padding-bottom: 24px;
  text-align: center;
}

.icon {
  font-size: 24px;
  color: $orangeColor;
  margin-bottom: 4px;
}

.heading {
  font-family: $headingFont;
  font-size: 24px;
  line-height: 1;
}

.dates {
  font-size: 18px;
  line-height: 1.33;
  margin-top: 4px;
}

.body {
  text-align: center;
  padding: 48px 0;
}

.innerHeading {
  font-size: 24px;
  line-height: 1.17;
}

.fileName {
  font-size: 18px;
  line-height: 1.33;
  margin-top: 8px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -8px;

  & > * {
    flex: 1;
    margin: 8px;
  }
}

.spinner {
  font-size: 0;
  border-style: solid;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: auto;
  width: 60px;
  height: 60px;
  border-width: 6px;
  border-color: rgba($orangeColor, 0.2);
  border-top-color: $orangeColor;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
