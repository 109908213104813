@import 'src/assets/css/vars.scss';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: $greyColor;
  color: $whiteColor;
  padding: 8px 24px;
  text-align: center;
  box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.1);
}
