@import 'src/assets/css/vars.scss';

.wrapper {
  margin: 24px auto 64px auto;
  max-width: 328px;
}

.note {
  position: relative;
  padding: 12px 20px;
  background-color: $lightOrangeColor;
  border-radius: 20px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  margin: 16px 0 24px 0;

  &:after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 0px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid $lightOrangeColor;
  }

  & em {
    font-family: $boldFont;
  }
}

.item {
  & + & {
    margin-top: 8px;
  }
}

.footer {
  margin-top: 24px;
}

.privacyRow {
  margin-top: 10px;
  text-align: left;
  font-size: 12px;
  line-height: 14px;

  & a {
    color: $orangeColor;
    transition: color 0.12s linear;
  }

  @media (hover: hover) {
    & a:hover {
      color: $greyColor;
    }
  }
}
