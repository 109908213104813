@font-face {
  font-family: 'icons';
  src: url('../fonts/icons.eot?ekji5c');
  src: url('../fonts/icons.eot?ekji5c#iefix') format('embedded-opentype'),
    url('../fonts/icons.ttf?ekji5c') format('truetype'),
    url('../fonts/icons.woff?ekji5c') format('woff'),
    url('../fonts/icons.svg?ekji5c#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-trash:before {
  content: '\e900';
}
.icon-arrow-left:before {
  content: '\e901';
}
.icon-arrow-right:before {
  content: '\e902';
}
.icon-xmark:before {
  content: '\e903';
}
.icon-share:before {
  content: '\e904';
}
.icon-edit:before {
  content: '\e905';
}
.icon-plus:before {
  content: '\e906';
}
.icon-checkmark:before {
  content: '\e907';
}
.icon-calendar:before {
  content: '\e908';
}
.icon-copy:before {
  content: '\e909';
}
.icon-dots:before {
  content: '\e90a';
}
.icon-info:before {
  content: '\e90b';
}
.icon-chevron-down:before {
  content: '\e90c';
}
.icon-chevron-up:before {
  content: '\e90d';
}
.icon-horse:before {
  content: '\e90e';
}
.icon-arrow-down:before {
  content: '\e90f';
}
.icon-angle-down:before {
  content: '\e910';
}
