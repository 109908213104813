@import 'src/assets/css/vars.scss';

.wrapper {
}

.icon {
  margin-bottom: 8px;
}

.blockquote {
  position: relative;
  font-weight: 400;
  font-size: 30px;
  line-height: 1.2;
  white-space: pre-line;
  padding-bottom: 16px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 122px;
    height: 3px;
    border-radius: 3px;
    background-color: $orangeColor;
  }
}
