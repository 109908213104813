@import 'src/assets/css/vars.scss';

.wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 162px;
    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba($whiteColor, 0) 0%,
      $whiteColor 70.31%
    );
    pointer-events: none;
  }
}

.btn {
  position: absolute;
  left: 50%;
  bottom: 24px;
  z-index: 10;
  transform: translateX(-50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $orangeColor;
  color: $whiteColor;
  padding: 0;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  font-size: 0;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3));
  transition: color 0.12s ease-out;

  &:before {
    content: '\e90e';
    @include inline-icon;
    font-size: 36px;
  }

  @media (hover: hover) {
    &:hover {
      color: $greyColor;
    }
  }
}

.plusIcon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: $greyColor;
  color: $orangeColor;

  &:before {
    content: '\e906';
    @include inline-icon;
    font-size: 14px;
  }
}
