@import 'src/assets/css/vars.scss';

.wrapper {
  padding: 24px 8px;
  text-align: center;

  @media screen and (min-width: 380px) {
    padding: 24px;
  }
}

.heading {
  font-family: $headingFont;
  font-size: 36px;
  line-height: 1;
  margin-bottom: 24px;
}
