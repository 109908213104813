@import 'src/assets/css/vars.scss';

.wrapper {
  position: relative;
}

.toggle {
  width: 100%;
  min-height: 44px;
  background-color: $whiteColor;
  color: $greyColor;
  border: 1px solid $borderColor;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  padding: 0 44px 0 14px;
  margin: 0;
  text-align: left;
  transition: border-color 0.16s linear, color 0.16s linear;

  @media (hover: hover) {
    &:hover {
      border-color: $greyColor;
    }
  }

  &:focus {
    border-color: $orangeColor;
    outline: none;
  }

  &:disabled {
    pointer-events: none;
  }

  &:after {
    content: '\e908';
    @include inline-icon;
    font-size: 20px;
    color: $orangeColor;
    position: absolute;
    top: 11px;
    right: 12px;
  }
}

.menu {
  position: absolute;
  align-items: center;
  top: 100%;
  right: 0;
  z-index: 101;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  padding: 4px 0;
  transform: translateY(-4px);
  transition: visibility 0s ease-out 0.13s, opacity 0.13s ease-out,
    transform 0.13s ease-out;

  .wrapper.isOpen & {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: visibility 0s ease-out 0s, opacity 0.13s ease-out,
      transform 0.13s ease-out;
  }
}

.pickerWrapper {
  background-color: $whiteColor;
  box-shadow: 0px 0px 20px rgba(213, 213, 213, 0.4);
  border-radius: 5px;
  padding: 16px;
}
