.rdp-caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  text-align: left;
  margin-bottom: 12px;
}

.rdp-table {
  margin: 0;
  min-width: calc(32px * 7);
  width: 100%;
  border-collapse: collapse;
}

.rdp-caption_label {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0 0.25em;
  white-space: nowrap;
  color: currentColor;
  border: 0;
  border: 2px solid transparent;
  font-family: $headingFont;
  font-size: 20px;
}

.rdp-nav {
  white-space: nowrap;
}

.rdp-nav_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0.25em;
  border-radius: 100%;
}

.rdp-head_cell {
  vertical-align: middle;
  font-family: $boldFont;
  font-size: 0.75em;
  text-align: center;
  height: 32px;
  padding: 0;
}

.rdp-cell {
  width: 32px;
  height: 32px;
  padding: 0;
  text-align: center;
}

.rdp-button_reset {
  appearance: none;
  position: relative;
  margin: 0;
  padding: 0;
  cursor: default;
  color: inherit;
  background: none;
  font: inherit;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.rdp-button {
  border: 2px solid transparent;
}

.rdp-weeknumber,
.rdp-day {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 32px;
  max-width: 32px;
  height: 32px;
  margin: 0;
  border-radius: 50%;
  transition: background-color 0.12s linear;

  @media (hover: hover) {
    &:not(.rdp-day_selected):hover {
      background-color: rgba($orangeColor, 0.6);
    }
  }
}

.rdp-button:not([disabled]) {
  cursor: pointer;
}

.rdp-day_today {
  font-weight: bold;
  color: $orangeColor;
}

.rdp-day_selected {
  background-color: $orangeColor;
  color: $whiteColor;
}

.rdp-day_disabled {
  color: $strokeColor;
  pointer-events: none;
}
