@import 'src/assets/css/vars.scss';

.listContainer {
  position: relative;
  margin: 0 -20px 64px 0;

  @media screen and (min-width: $screen-md-min) {
    margin: 0 24px 64px 0;
  }
}

.listWrapper {
  position: relative;
}

.tableScrollWrapper {
  position: relative;
  overflow: auto;
  max-width: 100%;

  @media screen and (min-width: $screen-md-min) {
    overflow: hidden;
  }
}

.listTable {
  table-layout: fixed;
  width: 100%;
  font-size: 14px;
  line-height: 1.14;
}

.titleCellGroup {
  width: 156px;

  @media screen and (min-width: $screen-md-min) {
    width: 238px;
  }
}

.tableTitleCell {
  position: sticky;
  left: 0;
  z-index: 10;
  background-color: $bgColor;
  padding: 12px 12px 12px 0;

  @media screen and (min-width: $screen-md-min) {
    min-width: 269px;
    width: 269px;
  }
}

.tableCell {
  text-align: center;
}

.tableSectionTitle {
  color: $orangeColor;
  font-size: 14px;
  line-height: 1.14;
  text-transform: uppercase;
}

.tableItemTitle {
  font-size: 14px;
  line-height: 1.14;
}

.checkmark {
  color: $mutedColor;
  font-size: 20px;

  &.isActive {
    color: $greyColor;
  }
}

.dayLabel {
  display: block;
  font-size: 12px;
  line-height: 1;
  opacity: 0.4;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 6px;
}

.dateBtn {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 43px;
  height: 48px;
  padding: 0;
  border: 1px solid $borderColor;
  border-radius: 5px;
  color: $greyColor;

  &.hasValue {
    border-color: $orangeColor;
    color: $orangeColor;
  }

  &:disabled {
    opacity: 0.4;
  }
}

.dateLabel {
  font-size: 20px;
  line-height: 1.2;
}

.monthLabel {
  font-size: 10px;
  line-height: 1.2;
  text-transform: uppercase;
}

.listNavArrow {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: $strokeColor;
  box-shadow: 0px 0px 20px rgba(213, 213, 213, 0.4);
  font-size: 0;
  padding: 0;
  position: absolute;
  top: 28px;
  z-index: 20;

  &.prev {
    left: 206px;
  }

  &.next {
    right: -32px;
  }

  &:before {
    @include inline-icon;
    font-size: 14px;
  }

  &.prev:before {
    content: '\e901';
  }

  &.next:before {
    content: '\e902';
  }

  &:disabled {
    opacity: 0.3;
  }

  @media screen and (max-width: $screen-sm-max) {
    display: none;
  }
}

.nav {
  padding-bottom: 16px;
  overflow-x: auto;
  white-space: nowrap;
  margin: 0 -20px;

  @media screen and (min-width: $screen-lg-min) {
    margin: 0;
  }
}

.navInner {
  display: inline-flex;
  justify-content: space-between;
  min-width: 100%;
  padding: 0 20px;

  @media screen and (min-width: $screen-lg-min) {
    padding-left: 244px;
    border-bottom: 1px solid $borderColor;
  }
}

.navLink {
  position: relative;
  display: inline-flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.12;
  padding: 8px 0;
  opacity: 0.4;
  flex: 1 0 auto;
  max-width: 150px;
  white-space: normal;
  height: 52px;
  border-bottom: 1px solid $borderColor;

  &:last-child {
    margin-right: 20px;
  }

  & + & {
    padding-left: 16px;
  }

  &.isActive {
    opacity: 1;
  }

  &.isActive:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 3px;
    background-color: $orangeColor;
    border-radius: 3px;
  }
}
