@import 'src/assets/css/vars.scss';

.wrapper {
  padding: 24px;
  text-align: center;
}

.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  background-color: $orangeColor;
  margin-bottom: 24px;
  color: $whiteColor;
  font-size: 36px;

  &:before {
    content: '\e90e';
    @include inline-icon;
  }
}

.heading {
  font-family: $headingFont;
  font-size: 36px;
  line-height: 1;
  margin-bottom: 24px;
}
