@import 'src/assets/css/vars.scss';

.form {
  height: 100%;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.listWrapper {
  flex: 1;
  padding: 0 20px 162px 20px;
  margin: 0 -20px;

  @media screen and (min-width: $screen-md-min) {
    max-width: 368px;

    margin: 0 auto;
  }
}

.list {
}

.section {
  padding: 16px 0;

  & + & {
    padding-top: 26px;
  }
}

.sectionTitle {
  font-family: $headingFont;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  text-align: center;
  margin-bottom: 28px;
  padding: 12px 0;
  width: 100%;

  @media screen and (max-width: $screen-sm-max) {
    position: sticky;
    top: 0;
    z-index: 10;
    background: linear-gradient(
      180deg,
      $whiteColor 70%,
      rgba($whiteColor, 0) 100%
    );
  }
}

.sectionInner {
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & + & {
    margin-top: 16px;
  }
}

.itemLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  margin-right: 16px;
}

.itemInputWrapper {
}

.navWrapper {
  overflow: auto;
  margin: 0 -20px;
}

.navInner {
  display: inline-flex;
  border-bottom: 1px solid $borderColor;
  margin: 0 20px;
}

.navLink {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.12;
  padding: 8px 8px;
  opacity: 0.4;
  width: 160px;

  &:last-child {
    margin-right: 20px;
  }

  & + & {
    margin-left: 16px;
  }

  &.isActive {
    opacity: 1;
  }

  &.isActive:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 3px;
    background-color: $orangeColor;
    border-radius: 3px;
  }
}
