@font-face {
  font-family: 'hypatia-sans-pro';
  src: url('../fonts/hypatia-sans-pro.woff2') format('woff2'),
    url('../fonts/hypatia-sans-pro.woff') format('woff'),
    url('../fonts/hypatia-sans-pro.otf') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'RobotoCondensed-Regular';
  src: url('../fonts/RobotoCondensed-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'RobotoCondensed-Bold';
  src: url('../fonts/RobotoCondensed-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
