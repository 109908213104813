@import 'src/assets/css/vars.scss';

.wrapper {
  width: 348px;
  background-color: $whiteColor;
  border: 1px solid $borderColor;
  box-shadow: 0px 0px 20px rgba(213, 213, 213, 0.4);
  border-radius: 10px;
  text-align: center;
  padding: 48px 24px;
  margin-top: 24px;
}

.msg {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.33;
}

.footer {
  margin-top: 32px;
}
