@import 'src/assets/css/vars.scss';

.wrapper {
  padding: 10px 8px;
  display: flex;
  flex-direction: column;

  flex: 1;
  min-width: 328px;
  max-width: 33.33%;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: $whiteColor;
  box-shadow: 0px 0px 20px rgba(213, 213, 213, 0.4);
  border-radius: 10px;
  padding: 28px 16px 24px 16px;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerMain {
}

.title {
  font-family: $headingFont;
  font-style: normal;
  font-size: 20px;
  line-height: 1.2;
}

.age {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}

.actions {
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
}

.reportList {
  margin-top: 24px;
}

.reportItem {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border: 1px solid $borderColor;
  padding: 16px 14px;
  border-radius: 10px;

  & + & {
    margin-top: 10px;
  }
}

.reportDates {
}

.reportDate {
  & + & {
    margin-top: 3px;
  }
}

.reportDateLabel {
  display: inline-block;
  font-size: 12px;
  line-height: 2;
  color: $orangeColor;
  width: 32px;
}

.reportDateValue {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}

.reportSymptoms {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
}

.reportSymptomsLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 2;
  color: $orangeColor;
}

.reportSymptomsCount {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: $strokeColor;
  margin-top: 2px;

  & strong {
    color: $greyColor;
  }
}

.reportActions {
  margin: auto 0;
}

.footer {
  padding-top: 10px;
  margin-top: auto;
}

.tooltip {
  position: relative;

  & i {
    font-size: 16px;
    color: $strokeColor;
  }
}

.tooltipContainer {
  position: absolute;
  bottom: 30px;
  left: -72px;
  width: 160px;
  background-color: #f7e0ce;
  border-radius: 10px;
  padding: 10px;
  font-weight: 400;
  font-size: 8px;
  line-height: 1.25;
  color: $greyColor;
  opacity: 0;
  visibility: hidden;
  transform: translateY(4px);
  transition: opacity 0.12s ease-out, visibility 0s ease-out 0.12s,
    transform 0.12s ease-out;

  .tooltip:hover & {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: opacity 0.12s ease-out, visibility 0s ease-out 0s,
      transform 0.12s ease-out;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 0px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #f7e0ce;
  }
}
