$bgColor: #f7f8fa;
$greyColor: #333333;
$orangeColor: #f5821f;
$lightOrangeColor: #f7e0ce;
$strokeColor: #dbdbdb;
$borderColor: #dcdcdc;
$mutedColor: #ececec;
$whiteColor: #ffffff;
$dangerColor: #ea4335;

$primaryFont: 'RobotoCondensed-Regular', sans-serif;
$boldFont: 'RobotoCondensed-Bold', sans-serif;
$headingFont: 'hypatia-sans-pro', sans-serif;

$screen-lg-min: 1024px;
$screen-md-max: $screen-lg-min - 1;
$screen-md-min: 768px;
$screen-sm-max: $screen-md-min - 1;

@mixin inline-icon {
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
